/* In your main CSS file or index.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


/* Add this to your global CSS file or inside a style tag */
@keyframes blob {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
}

.animate-blob {
  animation: blob 10s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

.animation-delay-6000 {
  animation-delay: 6s;
}

.animation-delay-8000 {
  animation-delay: 8s;
}

.animation-delay-10000 {
  animation-delay: 10s;
}

.animation-delay-12000 {
  animation-delay: 12s;
}

.animation-delay-14000 {
  animation-delay: 14s;
}

.animation-delay-16000 {
  animation-delay: 16s;
}

.animation-delay-18000 {
  animation-delay: 18s;
}

.animation-delay-20000 {
  animation-delay: 20s;
}

.animation-delay-22000 {
  animation-delay: 22s;
}

.animation-delay-24000 {
  animation-delay: 24s;
}

.animation-delay-26000 {
  animation-delay: 26s;
}